<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8">
    <v-form class="tw-py-4" v-model="valid" @submit.prevent="onCalculate" ref="form">
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Surname</label>
            <v-text-field type="text" name="surname" id="surname" v-model="form.surname" :rules="[required('Surname')]"
              required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Other Names</label>
            <v-text-field type="text" name="othernames" id="othernames" v-model="form.othernames"
              :rules="[required('Other Name(s)')]" required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Email Address</label>
            <v-text-field type="email" name="email" id="email" v-model="form.email"
              :rules="[required('Email Address'), emailFormat()]" required></v-text-field>
          </div>
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-4">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Date of Birth</label>
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="form.dateOfBirth" persistent-hint readonly v-bind="attrs" v-on="on"
                  :rules="[required('Date of Birth')]"></v-text-field>
              </template>
              <v-date-picker id="dateOfBirth" :active-picker.sync="activePicker" v-model="form.dateOfBirth" no-title
                @input="menu = false" :max="givenYearsAgo(18)" :min="givenYearsAgo(65)" color="primary"></v-date-picker>
            </v-menu>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Term in Years</label>
            <v-select id="term" v-model="form.term" :items="getTerms" dense
              :rules="[required('Term in Years')]"></v-select>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">{{ amountTitle }}</label>
            <vuetify-money id="amount" ref="amount" v-model="form.amount" name="amount" :rules="[
              required('amount is required'),
              switch1 ? minAmount(amountTitle, 100000) : null,
            ]" :options="options"></vuetify-money>
            <small v-if="switch1" class="c-yellow-text tw-italic">
              <v-icon size="14" color="secondary">mdi-information-outline</v-icon> Minimum sum
              assured is KES. 100,000</small>
          </div>
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-4">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text" for="premiumSwitch">Premium / Sum Assured (Switch)
              <v-switch class="pl-10 pt-4" id="premiumSwitch" v-model="switch1"></v-switch></label>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Payment Frequency</label>
            <div></div>
            <v-select id="freqOfPayment" v-model="form.freqOfPayment" :items="payFreq" item-text="label"
              item-value="value" dense :rules="[required('Payment Frequency')]"></v-select>
          </div>
          <div class="tw-px-2 md:tw-px-6 tw-w-full md:tw-w-1/3 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Additional Benefits</label>

            <!-- Responsive flex-column layout for small screens, grid for larger screens -->
            <div class="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-1 tw-gap-y-2 md:tw-gap-y-6 tw-mt-2">
              <div v-for="(benefit, i) in benefits" :key="i" class="tw-my-2 md:tw-my-0">
                <v-checkbox
                  v-if="!((form.freqOfPayment === 'Sp' || form.freqOfPayment === 'A') && benefit.coverName === 'Retrenchment')"
                  v-model="form.benefits" id="benefits" :value="benefit.coverProdCode"
                  :label="benefit.coverName"></v-checkbox>
              </div>
            </div>
          </div>

        </div>
        <div class="tw-w-full tw-px-2 lg:tw-px-6 tw-flex tw-justify-center tw-mt-3 md:tw-mt-6">
          <v-btn color="secondary" :loading="loading" :disabled="!valid" type="submit">Calculate</v-btn>
        </div>
      </div>
    </v-form>

    <AuthModal :show="showAuthModal" :loading="loading2" @submit="submitAuthModal" />

    <OtpModal :show="showOtpModal" :loading="loading2" :email="authEmail" @submit="submitOtpModal"
      @resend-otp="handleResendOTP" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import validations from '@/utils/validations';
import { age, givenYearsAgo } from '@/utils/time';
import AuthModal from './AuthModal.vue';
import OtpModal from './OtpModal.vue';

export default {
  name: 'ClientQuotation',
  components: {
    AuthModal,
    OtpModal,
  },
  props: ['productData', 'benefits', 'terms'],
  data() {
    return {
      valid: false,
      showAuthModal: false,
      showOtpModal: false,
      dateFormatted: '',
      menu: false,
      time: null,
      menu2: false,
      loading: false,
      loading2: false,
      authEmail: '',
      form: {
        name: '',
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        term: null,
        freqOfPayment: '',
        benefits: [],
        amount: null,
      },
      ...validations,
      options: {
        locale: 'en',
        prefix: 'KES',
        suffix: '',
        precision: 0,
      },
      amountTitle: 'Sum Assured',
      switch1: true,
      amount: null,
      activePicker: null,
      payFreq: [
        { label: 'Monthly', value: 'M' },
        { label: 'Quarterly', value: 'Q' },
        { label: 'Semi Annually', value: 'S' },
        { label: 'Annually', value: 'A' },
        { label: 'Single Premium', value: 'Sp' },
      ],
    };
  },
  watch: {
    async switch1(val) {
      this.amountTitle = val ? 'Sum Assured' : 'Premium';
      await this.$nextTick();
      this.$refs.form.validate();
    },
    'form.dateOfBirth': async function (val) {
      await this.$nextTick();
      if (this.age(val) >= 50) this.form.term = null;
    },
    'form.freqOfPayment': function (val) {
      if (val === 'A' || val === 'Sp') this.form.benefits = [];
    },
    menu() {
      setTimeout(() => {
        this.activePicker = 'YEAR';
      });
    },
  },
  computed: {
    sumAssured: {
      get() {
        return this.switch1 ? this.form.amount : 0;
      },
    },
    ...mapState({
      agentCode: (state) => (state.auth.userInfo ? state.auth.userInfo.agnCode : 0),
      prospectCode: (state) => (state.quote.clientResp ? state.quote.clientResp.prospectCode : null),
    }),
    premium: {
      get() {
        return this.switch1 ? 0 : this.form.amount;
      },
    },
    getTerms() {
      const newTerms = this.form.dateOfBirth
        ? this.terms.filter((i) => i <= 70 - this.age(this.form.dateOfBirth))
        : this.terms;
      return newTerms?.map(String);
    },
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'clientquotation_page_visited_by_agent', {
        event_category: 'client quotation component created',
        event_label: 'client quotation component created',
      });
    }
  },
  methods: {
    submitAuthModal(authForm) {
      this.loading2 = true;
      this.authEmail = authForm.prospectEmail; // Store the email
      const clientData = {
        ...authForm,
      };
      axios
        .post('/lmsagent/quotations/saveProspect', clientData)
        .then((res) => {
          const clientResp = res.data;
          localStorage.setItem('clientResp', JSON.stringify(clientResp));
          return axios.post(
            `/lmsagent/quotations/generateProspectOtpRequest?prospectCode=${clientResp.prospectCode}`,
          );
        })
        .then(() => {
          this.showAuthModal = false;
          this.showOtpModal = true;
          this.loading2 = false;
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    submitOtpModal(otp) {
      if (otp.length !== 6) {
        this.$toast.error('OTP must be 6 digits long');
        return;
      }

      this.loading2 = true;
      const storedClientResp = JSON.parse(localStorage.getItem('clientResp'));
      const { prospectCode } = storedClientResp;

      axios
        .post(`/lmsagent/quotations/verifyProspectOtpRequest?prospectCode=${prospectCode}&otp=${otp}`)
        .then((res) => {
          if (res.data.responseStatus.status === 0) {
            this.$toast.error(res.data.responseStatus.description || 'OTP verification failed. Please try again.');
          } else {
            this.$toast.success('OTP verified successfully!', res.data.responseStatus.description);
            this.saveQuote();
          }
        })
        .catch((error) => {
          this.$toast.error(`An error occurred during OTP verification: ${error.message}. Please try again.`);
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
    saveQuote() {
      const formData = this.prepareFormData();
      axios
        .post('/lmsagent/quotations/saveQuote', formData)
        .then((res) => {
          const quotationResp = res.data;

          localStorage.setItem('quotationResp', JSON.stringify(quotationResp));
          this.$store.commit('quote/clientInfo', quotationResp);
          this.showOtpModal = false;
          this.$emit('to-quote-summary', quotationResp);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(`An error occurred: ${error.message}`);
        });
    },
    prepareFormData() {
      this.form.name = `${this.form.surname} ${this.form.othernames}`;
      return {
        quoteCode: 0,
        quoteAgentCode: this.agentCode,
        clientCode: 0,
        ...this.form,
        ...this.productData,
        sumAssured: this.switch1 ? parseInt(this.form.amount, 10) : 0,
        premium: this.switch1 ? 0 : parseInt(this.form.amount, 10),
      };
    },
    onCalculate() {
      if (!this.isAuthenticated) {
        this.showAuthModal = true;
        return;
      }
      this.loading = true;
      this.form.name = `${this.form.surname} ${this.form.othernames}`;
      this.$store.commit('userPersonalData/updatePersonalData', this.form);
      const formData = {
        quoteCode: 0,
        quoteAgentCode: this.agentCode,
        clientCode: 0,
        ...this.form,
        ...this.productData,
        ...{
          sumAssured: parseInt(this.sumAssured, 10),
          premium: parseInt(this.premium, 10),
        },
      };
      axios
        .post('/lmsagent/quotations/saveQuote', formData)
        .then((res) => {
          const quotationResp = res.data;
          this.$store.commit('quote/clientInfo', quotationResp);
          this.$emit('to-quote-summary', quotationResp);
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
    },
    handleResendOTP() {
      const storedClientResp = JSON.parse(localStorage.getItem('clientResp'));
      const { prospectCode } = storedClientResp;

      this.loading2 = true;

      axios.post(`/lmsagent/quotations/generateProspectOtpRequest?prospectCode=${prospectCode}`)
        .then(() => {
          this.$toast.success('OTP resent successfully');
        })
        .catch((error) => {
          this.$toast.error('Failed to resend OTP. Please try again.', error.message);
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
    givenYearsAgo,
    age,
  },
};
</script>
