<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8">
    <v-form class="tw-py-4" v-model="valid" @submit.prevent="onCalculate" ref="form">
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Surname</label>
            <v-text-field type="text" name="surname" id="surname" v-model="form.surname" :rules="[required('Surname')]"
              required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Other Names</label>
            <v-text-field type="text" name="othernames" id="othernames" v-model="form.othernames"
              :rules="[required('Other Name(s)')]" required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Email Address</label>
            <v-text-field type="email" name="email" id="email" v-model="form.email"
              :rules="[required('Email Address'), emailFormat()]" required></v-text-field>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-flex-col lg:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Date of Birth</label>
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="form.dateOfBirth" persistent-hint readonly v-bind="attrs" v-on="on"
                  :rules="[required('Date of Birth')]"></v-text-field>
              </template>
              <v-date-picker id="dateOfBirth" :active-picker.sync="activePicker" v-model="form.dateOfBirth" no-title
                @input="menu = false" :max="givenYearsAgo(18)" :min="givenYearsAgo(65)" color="primary"></v-date-picker>
            </v-menu>
          </div>
          <div v-if="scanObject(productData, 'prodCode', null) === 2016194"
            class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Product Options</label>
            <v-select id="productOptionCode" v-model="form.optionCode" :items="productOptions" item-text="optionName"
              item-value="optionCode" dense required></v-select>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Term in Years</label>
            <v-select id="term" v-model="termsValue" :items="getTerms" dense required></v-select>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">{{ amountTitle }}</label>
            <vuetify-money id="amount" ref="amount" v-model="amount" name="amount" :rules="[
              required('amount is required'),
              switch1 ? minAmount(amountTitle, 100000) : null,
            ]" :options="options"></vuetify-money>
            <small v-if="switch1" class="c-yellow-text tw-italic">
              <v-icon size="14" color="secondary">mdi-information-outline</v-icon>Minimum sum assured is KES.
              100,000</small>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">
              Premium / Sum Assured (Switch)</label>
            <v-switch class="pl-10 pt-4" v-model="switch1"></v-switch>
          </div>
          <div class="tw-px-2 lg:tw-px-6 tw-mt-3 md:tw-mt-10 tw-w-full md:tw-w-1/3">
            <label class="tw-text-sm c-blue-text">Payment Frequency</label>
            <v-select id="freqOfPayment" v-model="form.freqOfPayment" :items="payFreq" item-text="label"
              item-value="value" dense :rules="[required('Payment Frequency')]"></v-select>

          </div>
          <div class="tw-px-2 lg:tw-px-6 tw-mt-3 md:tw-mt-5 tw-w-full md:tw-w-1/3">
            <label class="tw-text-sm c-blue-text">Additional Benefits</label>
            <v-row class="my-5">
              <div class="
                tw-grid tw-grid-cols-1
                md:tw-grid-cols-2
                tw-gap-2
                md:tw-gap-2
              ">
                <div v-for="(benefit, i) in benefits" :key="i">
                  <v-checkbox v-if="
                    !(
                      (form.freqOfPayment === 'Sp' ||
                        form.freqOfPayment === 'A') &&
                      benefit.coverName === 'Retrenchment'
                    )
                  " v-model="form.benefits" :value="benefit.coverProdCode" :label="benefit.coverName"></v-checkbox>
                </div>
              </div>
            </v-row>
          </div>
        </div>
        <div class="
            tw-w-full tw-px-2
            lg:tw-px-6
            tw-flex tw-justify-center tw-mt-3
            md:tw-mt-6
          ">
          <v-btn color="secondary" :loading="loading" :disabled="!valid" type="submit">Calculate</v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import validations from '@/utils/validations';
import { age, givenYearsAgo } from '@/utils/time';

export default {
  name: 'ClientQuotation',
  props: ['productData', 'benefits', 'terms', 'productOptions'],
  data() {
    return {
      dateFormatted: '',
      menu: false,
      time: null,
      menu2: false,
      valid: false,
      loading: false,
      form: {
        name: '',
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        freqOfPayment: '',
        benefits: [],
        optionCode: '',
      },
      termsValue: null,
      ...validations,
      options: {
        locale: 'en',
        prefix: 'KES',
        suffix: '',
        precision: 0,
      },
      amountTitle: 'Sum Assured',
      switch1: true,
      amount: null,
      activePicker: null,
      payFreq: [{ label: 'Monthly', value: 'M' },
        { label: 'Quarterly', value: 'Q' },
        { label: 'Semi Annually', value: 'S' },
        { label: 'Annually', value: 'A' }],
    };
  },
  watch: {
    async switch1(val) {
      this.amountTitle = val ? 'Sum Assured' : 'Premium';
      await this.$nextTick();
      this.$refs.form.validate();
    },
    'form.dateOfBirth': async function (val) {
      await this.$nextTick();
      if (this.age(val) >= 50) this.form.term = null;
    },
    'form.freqOfPayment': function (val) {
      if (val === 'A' || val === 'Sp') this.form.benefits = [];
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    'form.optionCode': function (val) {
      if (val) this.$emit('fetchTerms', val);
    },
  },
  mounted() {
    if (this.productData.prodCode === 2021200 || this.productData.prodCode === 2013102) {
      const SinglePremiumObj = {
        label: 'Single Premium',
        value: 'Sp',
      };
      this.payFreq.push(SinglePremiumObj);
    }
  },
  computed: {
    sumAssured: {
      get() {
        return this.switch1 ? this.amount : 0;
      },
    },
    ...mapState({
      agentCode: (state) => (state.auth.userInfo ? state.auth.userInfo.agnCode : 0),
    }),
    premium: {
      get() {
        return this.switch1 ? 0 : this.amount;
      },
    },
    getTerms() {
      return this.terms?.map((i) => (i.years ? i.years : i));
    },
    getTerm() {
      return this.terms?.find((i) => i.years === this.termsValue || i === this.termsValue);
    },
  },
  methods: {
    onCalculate() {
      this.form.name = `${this.form.surname} ${this.form.othernames}`;
      this.loading = true;
      this.$store.commit('userPersonalData/updatePersonalData', this.form);
      if (this.scanObject(this.productData, 'prodCode', null) === 2021200) this.form.optionCode = this.scanObject(this.productOptions, '0.optionCode', 2021217);
      if (this.scanObject(this.productData, 'prodCode', null) === 2021197) this.form.optionCode = this.scanObject(this.productOptions, '0.optionCode', 2021216);
      const formData = {
        quoteCode: 0,
        quoteAgentCode: this.agentCode,
        clientCode: 0,
        ...this.form,
        ...this.productData,
        ...[2016194, 2021197, 2021200].includes(this.scanObject(this.productData, 'prodCode', null))
          ? { term: this.termsValue }
          : { optionCode: this.getTerm.optionCode, term: this.getTerm.years },
        ...{
          sumAssured: parseInt(this.sumAssured, 10),
          premium: parseInt(this.premium, 10),
        },
      };
      axios
        .post('/lmsagent/quotations/saveQuote', formData)
        .then((res) => {
          const quotationResp = res.data;
          this.$store.commit('quote/clientInfo', quotationResp);
          this.$emit('to-quote-summary', quotationResp);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    givenYearsAgo,
    age,
  },
};
</script>
